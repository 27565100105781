import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  forwardRef,
} from 'react';
import ApplyCouponModal from './ApplyCouponModal';
// import { FormControl, TextField } from '@mui/material';
import CouponTipSectionLogic from './CouponTipSectionLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../../Assets/Payment/Edit.svg';
import RemoveIcon from '../../../Assets/Payment/removepoints.svg';
import CouponErrorIcon from '../../../Assets/Payment/couponErrorIcon.svg';
import { Button, TextField, FormControl } from '@mui/material';
import Validation from '../../../Constants/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import DeliveryAddressModal from './DeliveryAddressModal';
import DeliveryPriceModal from '../../Store/Wishlist/DeliveryPriceModal';
import CannotApplyIcon from '../../../Assets/Home/cannot-apply.svg';
import Slide from '@mui/material/Slide';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DeliveryAmountIcon from '../../../Assets/Payment/DeliveryAmount.svg';
import {
  removerErrorMessage,
  removeCoupon,
  removeCouponNew,
} from '../../../redux/features/Coupon/couponSlice';
import { DateTime } from 'luxon';
import { BASE_URL_SANDBOX, VALIDATE_PRODUCTS } from '../../../Constants/Config';
import axios from 'axios';
import { formatNumber } from '../../../utils';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CouponTipSection = ({
  setVisible,
  visible,
  userData,
  handleUpdateBillingInfo,
  selectedDeliveryAdderess,
  paymentType,
  paymentDPdata,
  setCardDetail,
  cardDetail,
  ValidateDeliveryDate,
  loading,
  setLoading,
  setPlacingOrderLoader,
}) => {
  const location = useLocation();
  const [disablePayButton, setDisablePayButton] = useState(false);
  const [sendNotifications, setSendNotifications] = useState(false);

  const merchantId = location.pathname.replace('/payment-cart/', '');
  const lp_sc_user_data = useSelector(
    (state) => state?.lpscPayment?.LpscPaymentData,
  );
  const accountInfoData = useSelector(
    (state) => state?.accountInfo?.accountInfoData,
  );
  const authuserData = useSelector((state) => state?.authUser?.UserData);
  const storeDataState = useSelector(
    (state) => state?.singleStores?.storesData?.store_data,
  );

  const cartDataState = useSelector((state) => state?.cart?.cartData);
  const storeProductDataState = useSelector((state) => state?.storeProductData);
  const VarientDataState = useSelector((state) => state?.variantCart);

  const {
    handleInputChange,
    values,
    setValues,
    handleAddingTip,
    handleApplyCoupon,
    couponData,
    handleRemoveCoupon,
    handleKeyDown,
    orderSummaryData,
    handleStoreCredit,
    handleUseStoreCredit,
    handleRemoveStoreCredit,
    handleInputLoyaltyPoint,
    handleUseLoyaltyPoint,
    handleRemoveLoyaltyPoint,
  } = CouponTipSectionLogic({ merchantId });

  const {
    place_Order,
    filterCartData,
    validateCardNumber,
    validateCvv,
    validateCardExpiredate,
    JsonItem,
  } = Validation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [visible]);

  const [finalAmount, setFinalAmount] = useState();
  const [finalAmountInfo, setFinalAmountInfo] = useState();
  const [summaryData, setSummaryData] = useState('');
  const [couponDiscount, setCouponDiscount] = useState();

  // useEffect(() => {
  //   console.log('summaryData: ', summaryData);
  // }, [summaryData]);

  const Navigate = useNavigate();
  const [cartData, setCartAllData] = useState([]);
  const [orderPlaceApiResponse, setOrderPlaceApiResponse] = useState('');
  const [couponValue, setCouponValue] = useState(false);

  const ref10Percent = useRef(null);
  const ref15Percent = useRef(null);
  const ref20Percent = useRef(null);
  const ref25Percent = useRef(null);
  const refCustom = useRef(null);
  const dispatch = useDispatch();
  const [myTip, setMyTip] = useState(''); // this is custom tip input by user
  const [tipValue, setTipValue] = useState(false);
  const handleOnChangeTip = useCallback(
    (e) => {
      e.preventDefault();

      const intTipVal = parseInt(e.target.value);

      if (!isNaN(intTipVal)) {
        setTipValue(true);
      }
    },
    [tipValue],
  );

  const [selectedElement, setSelectedElement] = useState('');

  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );

  // -------------
  useEffect(() => {
    if (myTip) {
      setMyTip('');
    }
    if (paymentType == 'newcash') {
      setValues({ ...values, tip: '', tipcustom: '' });
    }
  }, [paymentType]);
  // -------------

  // setting cart data
  useEffect(() => {
    // console.log('cartDataState: ', cartDataState);
    if (
      cartDataState &&
      VarientDataState?.variantCartData &&
      cartProductDetails
    ) {
      setCartAllData(
        filterCartData(
          cartDataState,
          VarientDataState?.variantCartData,
          cartProductDetails,
        ),
      );
    }
  }, [
    storeProductDataState?.storeProductData,
    cartDataState,
    VarientDataState?.variantCartData,
    cartProductDetails,
  ]);
  //========================
  useEffect(() => {
    dispatch(removeCouponNew());
    dispatch(removerErrorMessage());
  }, []);
  // ========================

  // setting order summary data
  useEffect(() => {
    if (orderSummaryData) {
      // console.log('orderSummaryData: ', orderSummaryData);
      setSummaryData(orderSummaryData);
    }
  }, [orderSummaryData]);

  // setting tip
  useEffect(() => {
    if (visible === 'myDeliveryPayment') {
      if (
        summaryData?.singleStoreData?.enable_tip === '1' &&
        summaryData?.singleStoreData?.store_option &&
        paymentType !== 'newcash' &&
        !values.tipRemoved &&
        !selectedElement
      ) {
        if (
          summaryData.singleStoreData.orderMethod === 'delivery' &&
          summaryData?.singleStoreData?.store_option?.default_tip_d !== '0'
        ) {
          const storedefaulttip =
            summaryData.singleStoreData.store_option.default_tip_d;

          const defaultTip = storedefaulttip;
          setMyTip('');
          setValues((previousState) => ({
            ...previousState,
            tip: defaultTip,
            tipcustom: '',
          }));

          let defaultRef;

          switch (storedefaulttip) {
            case '10':
              defaultRef = ref10Percent;
              break;
            case '15':
              defaultRef = ref15Percent;
              break;
            case '20':
              defaultRef = ref20Percent;
              break;
            case '25':
              defaultRef = ref25Percent;
              break;
            // Add more cases as needed for other tip values
            default:
            // Handle the case where storedefaulttip does not match any of the cases
          }

          if (defaultRef) {
            setSelectedElement(defaultRef.current);
            defaultRef?.current?.classList?.add('selected-tip');
          }
        } else {
          if (
            summaryData?.singleStoreData?.store_option?.default_tip_p !== '0' &&
            summaryData.singleStoreData.orderMethod === 'pickup'
          ) {
            const storedefaulttip =
              summaryData.singleStoreData.store_option.default_tip_p;
            const defaultTip = storedefaulttip;
            setMyTip('');
            setValues((previousState) => ({
              ...previousState,
              tip: defaultTip,
              tipcustom: '',
            }));

            let defaultRef;

            switch (storedefaulttip) {
              case '10':
                defaultRef = ref10Percent;
                break;
              case '15':
                defaultRef = ref15Percent;
                break;
              case '20':
                defaultRef = ref20Percent;
                break;
              case '25':
                defaultRef = ref25Percent;
                break;
              // Add more cases as needed for other tip values
              default:
              // Handle the case where storedefaulttip does not match any of the cases
            }

            if (defaultRef) {
              setSelectedElement(defaultRef.current);
              defaultRef?.current?.classList?.add('selected-tip');
            }
          }
        }
      } else {
        // handleCancleTip();
      }
    }
  }, [summaryData, visible, paymentType, values.tipRemoved, selectedElement]);

  useEffect(() => {
    // console.log('visible: ', visible);

    let subTotal = orderSummaryData?.cartProductSum ?? 0;
    let tax =
      totalTax(orderSummaryData?.taxData ? orderSummaryData?.taxData : {}) ?? 0;
    let conFee = orderSummaryData?.singleStoreData?.getConvFee ?? 0;

    const lp =
      parseFloat(values.usedLoyaltyPoint) > 0
        ? parseFloat(values.usedLoyaltyPoint)
        : 0;

    const storeCredit =
      parseFloat(values.usedStoreCredit) > 0
        ? parseFloat(values.usedStoreCredit)
        : 0;

    if (visible !== 'myDeliveryPayment') {
      let deliveryCharge =
        orderSummaryData?.singleStoreData?.orderMethod === 'delivery'
          ? parseFloat(orderSummaryData?.singleStoreData?.delivery_fee) > 0
            ? parseFloat(orderSummaryData?.singleStoreData?.delivery_fee)
            : 0
          : 0;

      const add =
        parseFloat(subTotal) +
        parseFloat(deliveryCharge) +
        parseFloat(conFee) +
        parseFloat(tax);

      const sub = parseFloat(lp) + parseFloat(storeCredit);

      const newFinalAmount = add.toFixed(2) - sub.toFixed(2);

      setFinalAmount(() =>
        parseFloat(newFinalAmount.toFixed(2)) <= 0
          ? 0
          : parseFloat(newFinalAmount.toFixed(2)),
      );
      setFinalAmountInfo({
        subTotal: subTotal,
        deliveryCharge: deliveryCharge,
        conFee: conFee,
        tax: tax,
      });
    } else {
      let deliveryCharge =
        orderSummaryData?.singleStoreData?.orderMethod === 'delivery'
          ? parseFloat(selectedDeliveryAdderess?.radiusData?.delivery_fee) > 0
            ? parseFloat(selectedDeliveryAdderess?.radiusData?.delivery_fee)
            : parseFloat(orderSummaryData?.singleStoreData?.delivery_fee) > 0
            ? parseFloat(orderSummaryData?.singleStoreData?.delivery_fee)
            : 0
          : 0;

      let tipcalamt = parseFloat(subTotal);

      const couponPrice = couponData
        ? calculateCouponAmount(couponData, parseFloat(subTotal))
        : 0;
      setCouponDiscount(parseFloat(couponPrice));

      const mytipamt =
        (values.tip !== '' && values.tip !== 'Custom') ||
        (paymentType !== 'newcash' && myTip !== '' && values.tip !== 'Custom')
          ? calculateTipAmount(
              values.tip ? values.tip + '%' : values.tipcustom,
              tipcalamt,
            )
          : 0;

      const add =
        parseFloat(subTotal) +
        parseFloat(deliveryCharge) +
        parseFloat(conFee) +
        parseFloat(mytipamt) +
        parseFloat(tax);

      const sub =
        parseFloat(couponPrice) + parseFloat(lp) + parseFloat(storeCredit);

      // console.log('add: ', add);
      // console.log('sub: ', sub);

      const grandTotal = add.toFixed(2) - sub.toFixed(2);

      setFinalAmount(() =>
        parseFloat(grandTotal.toFixed(2)) <= 0
          ? 0
          : parseFloat(grandTotal.toFixed(2)),
      );

      // console.log('grandTotal: ', grandTotal);
      // console.log('values: ', values);
      // console.log('subTotal: ', subTotal);
      // console.log('deliveryCharge: ', deliveryCharge);
      // console.log('conFee: ', conFee);
      // console.log('tax: ', tax);
      // console.log('couponPrice: ', couponPrice);
      // console.log('mytipamt: ', mytipamt);
      // console.log('=========================');

      setFinalAmountInfo({
        subTotal: subTotal,
        deliveryCharge: deliveryCharge,
        conFee: conFee,
        tax: tax,
        couponPrice: couponPrice,
        mytipamt: mytipamt,
        grandTotal,
      });
    }
  }, [orderSummaryData, couponData, values, visible, selectedDeliveryAdderess]);

  // useEffect(() => {
  //   console.log('visible: ', visible);
  //   console.log('couponData: ', couponData);
  // }, [couponData, visible]);

  // useEffect(() => {
  //   console.log('values: ', values);
  // }, [values]);

  const handleClick = (ref, my_tip) => {
    const element = ref.current;
    document.querySelectorAll('.quickvee-tip-percents span').forEach((el) => {
      if (el.classList.contains('selected-tip')) {
        el.classList.remove('selected-tip');
      }
    });

    if (element !== selectedElement) {
      handleAddingTip(my_tip);
      setSelectedElement(element);
      element.classList.add('selected-tip');

      if (my_tip !== 'Custom') {
        setMyTip('');
        setValues((previousState) => ({
          ...previousState,
          tipcustom: myTip ? myTip : '',
        }));
      } else {
        setMyTip(values.tipcustom);
        setValues((previousState) => ({
          ...previousState,
          tipcustom: values.tipcustom ? values.tipcustom : '',
        }));
      }
    } else {
      handleAddingTip('');
      setMyTip('');
      setSelectedElement('');
    }
  };

  const handleAddTip = () => {
    handleAddingTip('');
    setMyTip(values.tipcustom);
  };

  const handleCancleTip = () => {
    handleAddingTip('');
    setValues((previousState) => ({
      ...previousState,
      tipcustom: values.tipcustom !== '' ? values.tipcustom : '',
      tipRemoved: true,
    }));
  };

  // for DeliveryPrice modal
  const searchParams = new URLSearchParams(location.search);
  const orderMethodse = searchParams.get('orderMethod');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckoutwithLogin = () => {
    if (storeDataState?.orderMethod === 'delivery') {
      if (
        parseFloat(summaryData?.cartProductSum) <
        parseFloat(summaryData?.singleStoreData?.min_delivery_amt)
      ) {
        setOpen(true);
      } else {
        Navigate('/customer-login', {
          state: {
            merchantId: merchantId,
            orderMethod: orderMethod,
            page: 'cart',
          },
        });
      }
    } else {
      Navigate('/customer-login', {
        state: {
          merchantId: merchantId,
          orderMethod: orderMethod,
          page: 'cart',
        },
      });
    }
  };

  const handleRemoveTip = () => {
    document.querySelectorAll('.quickvee-tip-percents span').forEach((el) => {
      el.classList.remove('selected-tip');
    });
    setMyTip('');
    setTipValue(false);
    setSelectedElement('');
    setValues((previousState) => ({
      ...previousState,
      tipcustom: '',
      tip: '',
      tipRemoved: true,
    }));
  };

  const calculateCouponAmount = (couponData, amount) => {
    const { flag, min_amount, discount, maximum_discount } = couponData;

    if (flag === '0') {
      const price = (amount / 100) * discount;
      if (
        price > parseFloat(maximum_discount) &&
        parseFloat(maximum_discount) > 0 &&
        !isNaN(maximum_discount)
      ) {
        return parseFloat(maximum_discount);
      } else {
        return parseFloat(price);
      }
    } else {
      return parseFloat(discount);
    }
  };

  const calculateTipAmount = (value, grandTotalAmount) => {
    if (value.includes('%')) {
      const percentage = parseFloat(value);
      if (!isNaN(percentage)) {
        const tipAmount = (percentage / 100) * grandTotalAmount;
        return parseFloat(tipAmount);
      } else {
        return 0;
      }
    } else {
      const customTipAmount = parseFloat(value);
      if (!isNaN(customTipAmount)) {
        return customTipAmount;
      } else {
        return 0;
      }
    }
  };

  const stringToNumber = (input) => {
    const temp = input * 1;
    return parseFloat(temp);
  };

  let totalTax = (taxObj) => {
    let total = 0;
    for (const key in taxObj) {
      total = total + parseFloat(taxObj[key]);
    }

    // const tax = parseFloat(total).toFixed(2);
    // console.log('before tax: ', tax);
    // console.log('after tax: ', stringToNumber(tax));
    // console.log('total tax: ', total, taxObj);
    return total;
  };

  const getTaxes = (taxObj, taxPercentages) => {
    return Object.keys(taxObj).map((key) => {
      const taxData = taxPercentages.find((tax) => tax.title === key);
      return (
        <div key={key}>
          <span>
            {key === 'DefaultTax' ? 'Sales Tax' : key}{' '}
            {taxData && taxData.percent
              ? `(${
                  taxData.percent > 0 ? formatNumber(taxData.percent) : '0.00'
                }%)`
              : null}
          </span>
          <p>${parseFloat(taxObj[key])?.toFixed(2)}</p>
        </div>
      );
    });
  };

  const validateCartProducts = async () => {
    const Items = JsonItem(
      cartData,
      summaryData?.taxDetailApiData,
      storeProductDataState?.storeProductData?.all_collection,
      orderSummaryData?.percentageDeducCoupon ?? 0,
      orderSummaryData?.percentageDeducLoy ?? 0,
    );

    // console.log('items: ', Items);

    // return;
    // validating all cart products before placing order
    const validateProductsResponse = await axios.post(
      BASE_URL_SANDBOX + VALIDATE_PRODUCTS,
      {
        merchant_id: storeDataState?.merchant_id ?? '',
        items: JSON.stringify(Items),
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return validateProductsResponse;
  };

  // placing order functionality
  // const placeOrder = async () => {
  //   try {
  //     setPlacingOrderLoader(true);
  //     const response = await place_Order(
  //       selectedDeliveryAdderess,
  //       couponData,
  //       accountInfoData,
  //       authuserData,
  //       storeDataState,
  //       finalAmountInfo,
  //       cartData,
  //       summaryData?.taxDetailApiData,
  //       summaryData?.taxData,
  //       storeProductDataState?.storeProductData?.all_collection,
  //       paymentDPdata,
  //       orderSummaryData?.lp_sc_data ?? 0,
  //       orderSummaryData?.discountAmt ?? 0,
  //       orderSummaryData?.percentageDeducCoupon ?? 0,
  //       orderSummaryData?.percentageDeducLoy ?? 0,
  //       sendNotifications,
  //     );

  //     if (response?.status === 400) {
  //       setOrderPlaceApiResponse(response?.result);
  //     } else if (response?.result?.order_id) {
  //       setOrderPlaceApiResponse('');

  //       const orderId = response?.result?.order_id;
  //       const merchantId = storeDataState?.merchant_id;

  //       Navigate('/orderSummeryPage', {
  //         state: {
  //           orderId: orderId,
  //           merchantId: merchantId,
  //           orderMethod: summaryData?.singleStoreData?.orderMethod,
  //         },
  //       });

  //       dispatch(setCartData([]));

  //       const cartKey = `cart_${merchantId}`;
  //       localStorage.removeItem(cartKey);
  //     }
  //   } catch (error) {
  //     new Error(error);
  //   } finally {
  //     setLoading(false);
  //     setPlacingOrderLoader(false);
  //   }
  // };

  // handling all conditions & if all ok placing order
  const handlePlaceOrder = async () => {
    if (disablePayButton) return;
    setDisablePayButton(() => true);

    // first checking all Date and info is correct
    let validateDeliveryLaterDate = ValidateDeliveryDate();
    // if any input is not field which is required. stopping at that time only.
    if (!validateDeliveryLaterDate) {
      setDisablePayButton(() => false);
      return;
    }

    // start the loader instantly after Pay button is clicked
    setPlacingOrderLoader(() => true);

    // second checking all products are ok or not
    const validateProducts = await validateCartProducts();

    // checking if all cart products are valid or not
    if (validateProducts?.data?.status !== 200) {
      // navigate to cart page - done
      // mark out of stock products  - for this check all the cart products.. and loop over it.. - done
      // disable the button for proceeding.. - done

      // incase cart items are incorrect disable the Loader
      setPlacingOrderLoader(() => false);

      // setting cart page as active page
      setVisible('myPayment');
      sessionStorage.setItem('orderStatus', 'myPayment');

      const cartKey = `cart_${merchantId}`;
      const cartDataInLocalStorage = JSON.parse(localStorage.getItem(cartKey));

      // updating cart products if any of the product is out of stock from Validate products api
      const updatedCartData = cartDataInLocalStorage?.map((item) => {
        // with full details
        const cartProductItem = cartData.find(
          ({ cartProductId }) => cartProductId === item.cartProductId,
        );

        if (!cartProductItem) return item;

        // with limited details
        const validateProduct = validateProducts?.data?.result?.find(
          (product) => {
            if (cartProductItem.product.isvarient === '1') {
              return (
                product?.product_id === cartProductItem?.productId &&
                product?.variant_id === cartProductItem?.variantId &&
                !product.status
              );
            } else {
              return (
                product.product_id === cartProductItem?.productId &&
                !product.status
              );
            }
          },
        );
        if (validateProduct) {
          return {
            ...item,
            isOutOfStock:
              validateProduct.actual_qty === 'disable' ||
              parseFloat(validateProduct.actual_qty) <= 0
                ? true
                : false,
            isDisabled: validateProduct.actual_qty === 'disable',
            msg: validateProduct.msg,
            quantity:
              validateProduct.actual_qty !== 'disable' &&
              parseFloat(validateProduct.actual_qty) > 0
                ? parseFloat(validateProduct.actual_qty)
                : 0,
          };
        } else {
          return item;
        }
      });

      const removeOutOfStockProducts = updatedCartData.filter(
        (product) => product.isOutOfStock,
      );

      let removedProducts = [];

      removeOutOfStockProducts?.forEach((item) => {
        cartData?.forEach((prod) => {
          if (prod.cartProductId === item.cartProductId) {
            removedProducts.push(prod);
          }
        });
      });

      const productTitles = JSON.stringify(
        removedProducts.map((i) => {
          let variant = '';
          if (i.varients.length > 0) {
            variant = Object.values(i.varients.map((v) => v.varientVl)).join(
              '/',
            );
          }
          return Boolean(variant.trim())
            ? i.product.title + ' - ' + variant
            : i.product.title;
        }),
      );

      localStorage.setItem('outOfStockProducts', productTitles);

      const inStockProducts = updatedCartData.filter(
        (item) => !item.isOutOfStock,
      );

      localStorage.setItem(cartKey, JSON.stringify(inStockProducts));
      dispatch(setCartData(inStockProducts));
      return;
    }

    dispatch(removerErrorMessage());
    setValues({
      ...values,
      errors: {
        coupon: '',
      },
    });

    if (validateDeliveryLaterDate) {
      // enable the loading here..
      if (paymentType === 'newdebitcard') {
        let { errors } = cardDetail;
        // console.log('card errors: ', errors);

        const finalAmountIsAboveZero =
          parseFloat((finalAmountInfo?.grandTotal).toFixed(2)) > 0;

        // if grand total is above zero need to validate card details
        if (finalAmountIsAboveZero) {
          await validateCardNumber(cardDetail.cardnumber, errors);
          await validateCvv(cardDetail.cvv, errors);
          await validateCardExpiredate(cardDetail.expiredate, errors);
        }

        const cardConditions =
          cardDetail.errors.cvv === '' &&
          cardDetail.errors.cardnumber === '' &&
          cardDetail.errors.expiredate === '';

        setCardDetail((prevState) => ({
          ...prevState,
          errors,
        }));

        // console.log('finalAmountIsAboveZero: ', finalAmountIsAboveZero);
        // console.log('cardConditions: ', cardConditions);

        // 1) card details is proper allow...
        // 2) if store credit used and grand total is not 0 and card details is proper allow
        // 3) if store credit used and grand total is 0, dont check card details.. simply allow.
        if (
          (finalAmountIsAboveZero && cardConditions) ||
          !finalAmountIsAboveZero
        ) {
          try {
            // console.log('ye hai tax 1: ', summaryData?.taxData);
            // console.log('start loading 1...');
            const response = await place_Order(
              selectedDeliveryAdderess,
              couponData,
              accountInfoData,
              authuserData,
              storeDataState,
              finalAmountInfo,
              cartData,
              summaryData?.taxDetailApiData,
              summaryData?.taxData,
              storeProductDataState?.storeProductData?.all_collection,
              paymentDPdata,
              orderSummaryData?.lp_sc_data ?? 0,
              orderSummaryData?.discountAmt ?? 0,
              orderSummaryData?.percentageDeducCoupon ?? 0,
              orderSummaryData?.percentageDeducLoy ?? 0,
              sendNotifications,
            );

            console.log('response 1: ', response);

            // if no products found in cart and user trying to place order
            if (response?.error === 'No Cart Items' && !response?.status) {
              Navigate(
                `/merchant/${storeDataState?.merchant_id}?orderMethod=${summaryData?.singleStoreData?.orderMethod}`,
              );
              return;
            }

            if (response?.status === 400) {
              setLoading(false);
              setOrderPlaceApiResponse(response?.result);
            } else if (response?.result?.order_id) {
              setOrderPlaceApiResponse('');
              const orderId = response?.result?.order_id;
              const merchantId = storeDataState?.merchant_id;
              setLoading(false);
              Navigate('/orderSummeryPage', {
                state: {
                  orderId: orderId,
                  merchantId: merchantId,
                  orderMethod: summaryData?.singleStoreData?.orderMethod,
                },
              });
              dispatch(setCartData([]));
              const cartKey = `cart_${merchantId}`;
              localStorage.removeItem(cartKey);
            }
          } catch (error) {
            console.log('Error: ', error);
            // new Error(error);
          } finally {
            setLoading(false);
            // console.log('end loading 1...');
            setPlacingOrderLoader(() => false);
          }
        } else {
          setPlacingOrderLoader(() => false);
        }
      } else {
        try {
          // console.log('ye hai tax 2: ', summaryData?.taxData);
          // console.log('start loading 2...');
          const response = await place_Order(
            selectedDeliveryAdderess,
            couponData,
            accountInfoData,
            authuserData,
            storeDataState,
            finalAmountInfo,
            cartData,
            summaryData?.taxDetailApiData,
            summaryData?.taxData,
            storeProductDataState?.storeProductData?.all_collection,
            paymentDPdata,
            orderSummaryData?.lp_sc_data ?? 0,
            orderSummaryData?.discountAmt ?? 0,
            orderSummaryData?.percentageDeducCoupon ?? 0,
            orderSummaryData?.percentageDeducLoy ?? 0,
            sendNotifications,
          );

          console.log('response 2: ', response);

          // if no products found in cart and user trying to place order
          if (response?.error === 'No Cart Items' && !response?.status) {
            Navigate(
              `/merchant/${storeDataState?.merchant_id}?orderMethod=${summaryData?.singleStoreData?.orderMethod}`,
            );
            return;
          }

          if (response?.status === 400) {
            setOrderPlaceApiResponse(response?.result);
          } else if (response?.result?.order_id) {
            setOrderPlaceApiResponse('');
            const orderId = response?.result?.order_id;
            const merchantId = storeDataState?.merchant_id;
            setLoading(false);

            Navigate('/orderSummeryPage', {
              state: {
                orderId: orderId,
                merchantId: merchantId,
                orderMethod: summaryData?.singleStoreData?.orderMethod,
              },
            });
            dispatch(setCartData([]));
            const cartKey = `cart_${merchantId}`;
            localStorage.removeItem(cartKey);
          }
        } catch (error) {
          console.log('Error: ', error);
          // new Error(error);
        } finally {
          setLoading(false);
          // console.log('end loading 2...');
          setPlacingOrderLoader(() => false);
        }
      }
      // disable the loading here...
    }
    setLoading(false);
    setDisablePayButton(() => false);
  };

  // /guest-checkout
  let orderMethod = summaryData.singleStoreData
    ? summaryData.singleStoreData.orderMethod
    : '';

  const handleCheckasGuest = () => {
    if (storeDataState?.orderMethod === 'delivery') {
      if (
        parseFloat(summaryData?.cartProductSum) <
        parseFloat(summaryData?.singleStoreData?.min_delivery_amt)
      ) {
        // alert(`Your cart must contain at least ${summaryData?.singleStoreData?.min_delivery_amt} items to checkout.`);
        setOpen(true);
      } else {
        Navigate('/guest-checkout', {
          state: {
            merchantId: merchantId,
            orderMethod: orderMethod,
          },
        });
      }
    } else {
      Navigate('/guest-checkout', {
        state: {
          merchantId: merchantId,
          orderMethod: orderMethod,
        },
      });
    }
  };

  const handleCheckout = () => {
    // if (orderMethodse == 'delivery') {  // this was from url

    // this is from backend api response
    if (storeDataState?.orderMethod === 'delivery') {
      if (
        parseFloat(summaryData?.cartProductSum) <
        parseFloat(summaryData?.singleStoreData?.min_delivery_amt)
      ) {
        setOpen(true);
      } else {
        setVisible('myBillingInfo');
        sessionStorage.setItem('orderStatus', 'myBillingInfo');
      }
    } else {
      setVisible('myBillingInfo');
      sessionStorage.setItem('orderStatus', 'myBillingInfo');
    }
  };

  const handleCouponOnChange = (e) => {
    e.preventDefault();
    // setCouponValue(
    //   e.target.value && e.target.value.length <= 11 ? true : false,
    // );
    setCouponValue(e.target.value && e.target.value.length > 0 ? true : false);
    setValues((previousState) => ({
      ...previousState,
      loyaltyPoint: '',
      storeCredit: '',
    }));
  };

  const [activeHours, setActiveHours] = useState(false);
  const timerSelector = useSelector((state) => state.singleStores);
  useEffect(() => {
    timeSlote();
  }, [timerSelector]);

  const timeSlote = () => {
    const time_zone = timerSelector?.storesData?.store_data?.mer_timeZone;
    timerSelector.storesData?.store_data?.time_slot &&
      timerSelector.storesData.store_data.time_slot.forEach((result) => {
        const utcDateTime = DateTime.utc();
        const localDateTime = utcDateTime.setZone(time_zone);
        const dayName = localDateTime.toLocaleString({ weekday: 'long' });

        const curr_time = localDateTime.toFormat('HH:mm');
        if (dayName === result.day_name) {
          let open_time = result.open_time.split(',');
          let close_time = result.close_time.split(',');

          const convertToMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
          };

          const currTimeMinutes = convertToMinutes(curr_time);
          let isActive = false;
          open_time.forEach((open, index) => {
            const openTime = open;
            const closeTime = close_time[index];
            const openTimeMinutes = convertToMinutes(openTime);
            const closeTimeMinutes = convertToMinutes(closeTime);

            if (
              openTimeMinutes <= currTimeMinutes &&
              currTimeMinutes <= closeTimeMinutes
            ) {
              isActive = true;
              setActiveHours(true);
              return;
            } else {
              setActiveHours(false);
            }
          });
          // setDeliveryType(isActive ? 'DeliveryNow' : 'DeliveryLater');
          // setPickupType(isActive ? 'pickupNow' : 'pickupLater');
          // setActiveHours(isActive);
        } else {
        }
      });
  };

  const disableCheckout = useMemo(() => {
    return cartData?.some((element) => element?.isOutOfStock);
  }, [cartData]);

  const { isGuest } = useSelector((state) => state.authUser);

  const isPQLimitReached = useMemo(() => {
    return cartData?.some(
      (element) => element?.isOutOfStock && element?.pqLimitReached,
    );
  }, [cartData]);

  return summaryData ? (
    <>
      <div className="payment-stepper-checkout-item-top">
        <span>
          Order Summary {`(${cartDataState ? cartDataState.length : 0})`}{' '}
        </span>
      </div>
      <hr
        style={{
          background: '#D3D3D3',
          borderWidth: '0px',
          margin: '0px 0 10px 0 ',
          height: '1px',
        }}
      />
      <div className="payment-stepper-checkout-content">
        <div>
          <span> Subtotal</span>
          <p>${parseFloat(summaryData?.cartProductSum).toFixed(2)} </p>
        </div>
        {summaryData?.singleStoreData?.orderMethod === 'delivery' ? (
          <div>
            <span> Delivery Fee</span>{' '}
            <p>
              $
              {parseFloat(selectedDeliveryAdderess?.radiusData?.delivery_fee) >
              0
                ? parseFloat(
                    selectedDeliveryAdderess?.radiusData?.delivery_fee,
                  ).toFixed(2)
                : parseFloat(summaryData?.singleStoreData?.delivery_fee) > 0
                ? parseFloat(
                    summaryData?.singleStoreData?.delivery_fee,
                  ).toFixed(2)
                : '0.00'}
            </p>
          </div>
        ) : (
          ''
        )}
        {parseFloat(summaryData?.singleStoreData?.getConvFee) > 0 ? (
          <div>
            <span>Convenience Fee</span>{' '}
            <p>
              ${parseFloat(summaryData?.singleStoreData?.getConvFee).toFixed(2)}
            </p>
          </div>
        ) : (
          ''
        )}
        {summaryData.taxData
          ? getTaxes(summaryData.taxData, summaryData.taxDetailApiData)
          : ''}
        {visible === 'myDeliveryPayment' ? (
          <>
            <div className="quickvee-shop-coupon">
              <div className="quickvee-shop-coupon-header">
                <span>Coupons</span>
                <ApplyCouponModal
                  merchantId={merchantId}
                  couponSelected={couponData && couponData.name}
                  values={values}
                  setValues={setValues}
                />
              </div>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ position: 'relative', marginBottom: '10px' }}
                >
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Enter Coupon Code"
                      id="coupon-input"
                      name="coupon"
                      onKeyDown={handleKeyDown}
                      className="input-field"
                      value={
                        couponData !== ''
                          ? couponData && couponData.name + ' Applied'
                          : values.coupon
                      }
                      onChange={(e) => {
                        handleInputChange(e);
                        handleCouponOnChange(e);
                      }}
                    />
                    {couponData !== '' && couponData !== undefined ? (
                      <button
                        className="payment-summary-remove-btn payment-remove-coupon"
                        onClick={handleRemoveCoupon}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="payment-summary-apply-btn"
                        onClick={() => {
                          handleApplyCoupon(setCouponValue);
                        }}
                      >
                        {couponValue ? 'Apply' : ''}
                      </button>
                    )}
                    {values.errors.coupon !== '' &&
                    values.errors.coupon !== undefined ? (
                      <span className="payment-summary-input-error">
                        <img
                          src={CouponErrorIcon}
                          alt="coupon-not-found"
                          style={{ marginRight: '3px' }}
                        />

                        {values.errors.coupon}
                      </span>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </div>
              </div>
            </div>

            {isGuest !== 'yes' ? (
              <>
                {lp_sc_user_data?.loyalty_program?.enable_loyalty === '1' ? (
                  <>
                    {lp_sc_user_data?.customer_lp_point
                      ?.cus_loyality_points_amount > 0 ? (
                      <div className="quickvee-shop-coupon">
                        <div className="quickvee-shop-coupon-header">
                          <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                            Use Loyalty Points{' '}
                            <p className="loyality-points-para loyality-point-para-collpase">
                              {' '}
                              (You have{' '}
                              {parseFloat(
                                lp_sc_user_data?.customer_lp_point
                                  ?.cus_loyality_points,
                              ).toFixed(2)}{' '}
                              Points = $
                              {parseFloat(
                                lp_sc_user_data?.customer_lp_point
                                  ?.cus_loyality_points_amount,
                              ).toFixed(2)}
                              )
                            </p>{' '}
                          </span>
                        </div>
                        <div className="row">
                          <div
                            className="col-xs-12 col col-md-12"
                            style={{
                              position: 'relative',
                              marginBottom: '5px',
                            }}
                          >
                            <FormControl fullWidth>
                              <div>
                                <TextField
                                  className="input-field"
                                  label="Enter the $ amount"
                                  variant="outlined"
                                  inputProps={{ maxLength: 10 }}
                                  onChange={(e) =>
                                    handleInputLoyaltyPoint(
                                      e,
                                      orderSummaryData?.cartProductSum ?? 0,
                                      lp_sc_user_data?.customer_lp_point
                                        ?.cus_loyality_points_amount,
                                      couponDiscount,
                                      setCouponValue,
                                    )
                                  }
                                  value={
                                    values.usedLoyaltyPoint !== ''
                                      ? `$${values.usedLoyaltyPoint}`
                                      : values.loyaltyPoint
                                      ? `$` + values.loyaltyPoint
                                      : values?.loyaltyPoint
                                  }
                                  name="loyaltyPoint"
                                  disabled={
                                    values.usedLoyaltyPoint !== '' ||
                                    parseFloat(
                                      lp_sc_user_data?.loyalty_program
                                        ?.min_points_redemption,
                                    ) >
                                      parseFloat(
                                        lp_sc_user_data?.customer_lp_point
                                          ?.cus_loyality_points,
                                      )
                                  }
                                />

                                {(values.errors.loyaltyPoint === '' ||
                                  values.errors.loyaltyPoint === undefined) &&
                                values.loyaltyPoint !== '' &&
                                values.usedLoyaltyPoint === '' ? (
                                  <button
                                    className="payment-summary-apply-btn"
                                    onClick={(e) => {
                                      handleUseLoyaltyPoint(
                                        e,
                                        values.loyaltyPoint,
                                      );
                                    }}
                                  >
                                    Use Points
                                  </button>
                                ) : (
                                  ''
                                )}

                                {values.usedLoyaltyPoint !== '' ? (
                                  <button
                                    className="payment-summary-remove-btn"
                                    onClick={() => {
                                      handleRemoveLoyaltyPoint(setCouponValue);
                                    }}
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  ''
                                )}

                                <span className="payment-summary-input-error">
                                  {values.errors.loyaltyPoint}
                                </span>
                              </div>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                {lp_sc_user_data?.customer_store_credit > 0 ? (
                  <div className="quickvee-shop-coupon">
                    <div className="quickvee-shop-coupon-header">
                      <span>
                        Use Store Credit{' '}
                        <p>
                          {' '}
                          ($
                          {values.usedStoreCredit === ''
                            ? lp_sc_user_data?.customer_store_credit
                            : parseFloat(
                                lp_sc_user_data?.customer_store_credit -
                                  values.usedStoreCredit,
                              )?.toFixed(2)}
                          )
                        </p>
                      </span>
                    </div>

                    <div className="row">
                      <div
                        style={{
                          position: 'relative',
                          marginBottom: '5px',
                        }}
                      >
                        <FormControl fullWidth>
                          <div>
                            <TextField
                              autoComplete="off"
                              label="Enter the $ amount"
                              variant="outlined"
                              className="input-field"
                              inputProps={{ maxLength: 10 }}
                              onChange={(e) => {
                                handleStoreCredit(
                                  e,
                                  finalAmount.toFixed(2),
                                  lp_sc_user_data?.customer_store_credit,
                                  finalAmountInfo.mytipamt ?? 0,
                                );
                              }}
                              value={
                                values.usedStoreCredit !== ''
                                  ? `$${values.usedStoreCredit}`
                                  : values.storeCredit
                                  ? `$` + values.storeCredit
                                  : values.storeCredit
                              }
                              name="storeCredit"
                              disabled={
                                values.usedStoreCredit !== '' ? true : false
                              }
                            />
                            {values.usedStoreCredit !== '' ? (
                              <button
                                className="payment-summary-remove-btn"
                                onClick={() => {
                                  handleRemoveStoreCredit(setCouponValue);
                                }}
                              >
                                Remove
                              </button>
                            ) : (values.errors.storeCredit === '' ||
                                values.errors.storeCredit === undefined) &&
                              values.storeCredit !== '' ? (
                              <button
                                className="payment-summary-apply-btn"
                                onClick={handleUseStoreCredit}
                              >
                                Apply
                              </button>
                            ) : (
                              ''
                            )}

                            <span className="payment-summary-input-error">
                              {values.errors.storeCredit}
                            </span>
                          </div>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            {paymentType !== 'newcash' ? (
              <>
                {summaryData?.singleStoreData?.enable_tip === '1' ? (
                  <div className="quickvee-shop-coupon">
                    <div
                      className="quickvee-shop-coupon-header"
                      style={{ marginBottom: '0' }}
                    >
                      <span className="add-a-tip-heading">Tip</span>
                      {values.tip === 'Custom' ? (
                        <span
                          onClick={() => handleCancleTip()}
                          className="tip-cancle-btn"
                        >
                          Cancel
                        </span>
                      ) : (
                        ''
                      )}
                    </div>

                    <div
                      className="quickvee-tip-selection-section"
                      style={{ marginTop: '5px' }}
                    >
                      {values.tip !== 'Custom' ? (
                        <div className="quickvee-tip-percents">
                          <span
                            ref={ref10Percent}
                            onClick={() => handleClick(ref10Percent, 10)}
                          >
                            10%
                          </span>
                          <span
                            ref={ref15Percent}
                            onClick={() => handleClick(ref15Percent, 15)}
                          >
                            15%
                          </span>
                          <span
                            ref={ref20Percent}
                            onClick={() => handleClick(ref20Percent, 20)}
                          >
                            20%
                          </span>
                          <span
                            ref={ref25Percent}
                            onClick={() => handleClick(ref25Percent, 25)}
                          >
                            25%
                          </span>
                          <span
                            ref={refCustom}
                            onClick={() => handleClick(refCustom, 'Custom')}
                            className={myTip ? 'custom-tip-with-values' : ''}
                          >
                            {myTip ? (
                              <p>
                                {values.tipcustom}{' '}
                                <img
                                  src={EditIcon}
                                  style={{ marginLeft: '6px' }}
                                  alt="edit"
                                />
                              </p>
                            ) : (
                              'Custom'
                            )}
                          </span>
                        </div>
                      ) : (
                        <>
                          <div className="row quickvee-tip-custom">
                            <div
                              className="col-md-12 activeTip"
                              style={{
                                position: 'relative',
                                marginTop: '10px',
                                padding: 0,
                              }}
                            >
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="Enter Tip in $"
                                  variant="outlined"
                                  id="tip-input"
                                  autoComplete="off"
                                  placeholder="Enter Tip in $"
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    handleOnChangeTip(e);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 8,
                                    },
                                  }}
                                  value={
                                    values.tipcustom !== ''
                                      ? `$${values.tipcustom}`
                                      : values.tipcustom
                                  }
                                  name="tipcustom"
                                  className="input-field"
                                />
                              </FormControl>
                              {myTip === '' ? (
                                <button
                                  className="payment-add-update-tip"
                                  onClick={handleAddTip}
                                >
                                  {tipValue ? 'Add Tip' : ''}
                                </button>
                              ) : (
                                <button
                                  className="payment-add-update-tip"
                                  onClick={handleAddTip}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            {couponData?.name ? (
              <div>
                {' '}
                <span>Coupon Discount</span>{' '}
                <p style={{ color: '#00A86B' }}>
                  <img
                    onClick={handleRemoveCoupon}
                    src={RemoveIcon}
                    alt="remove"
                  />{' '}
                  - ${couponDiscount.toFixed(2)}
                </p>{' '}
              </div>
            ) : (
              ''
            )}

            {values.usedStoreCredit !== '' ? (
              <div>
                <span> Used Store Credit</span>
                <p style={{ color: '#00A86B' }}>
                  <img
                    onClick={handleRemoveStoreCredit}
                    src={RemoveIcon}
                    alt="remove"
                  />
                  - ${parseFloat(values?.usedStoreCredit).toFixed(2)}
                </p>
              </div>
            ) : (
              ''
            )}

            {values.usedLoyaltyPoint !== '' ? (
              <div>
                <span> Used Loyalty Points</span>
                <p style={{ color: '#00A86B' }}>
                  <img
                    onClick={handleRemoveLoyaltyPoint}
                    src={RemoveIcon}
                    alt="remove"
                  />
                  - ${parseFloat(values?.usedLoyaltyPoint).toFixed(2)}
                </p>
              </div>
            ) : (
              ''
            )}

            {(values.tip !== '' && values.tip !== 'Custom') ||
            (paymentType !== 'newcash' &&
              myTip !== '' &&
              values.tip !== 'Custom') ? (
              <div>
                <span>Tip</span>
                <p>
                  <img
                    src={RemoveIcon}
                    style={{ marginRight: '5px' }}
                    onClick={handleRemoveTip}
                    alt="remove"
                  />
                  {'$'}
                  {calculateTipAmount(
                    values.tip ? values.tip + '%' : values.tipcustom,
                    summaryData?.cartProductSum,
                  ).toFixed(2)}
                </p>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        <hr
          style={{
            background: '#D3D3D3',
            borderWidth: '0px',
            margin: '10px 0 0px 0',
            height: '1px',
          }}
        />
        <div className="quickvee-checkout-final-amount">
          <span>Grand Total </span>{' '}
          <p>$ {parseFloat(finalAmount).toFixed(2)}</p>
        </div>

        {/* showing checkbox for sending notifications */}
        {visible === 'myDeliveryPayment' &&
        storeDataState?.enable_order_status_msg !== '' ? (
          <div style={{ marginBottom: '10px' }}>
            <label
              htmlFor="sms-notification"
              style={{
                cursor: 'pointer',
                paddingLeft: '32px',
                position: 'relative',
                color: '#979797',
                fontSize: '14px',
                fontFmily: 'QuicksandSemiBold',
              }}
            >
              {/* sms_notify: on off */}
              <input
                checked={sendNotifications}
                onClick={() => setSendNotifications((prev) => !prev)}
                type="checkbox"
                className="available-coupons-checkbox"
                name="coupon"
                id="sms-notification"
                style={{
                  height: '18px',
                  width: '18px',
                  transform: 'translate(7px, 9px)',
                  position: 'absolute',
                  left: '0px',
                  top: '-7px',
                  cursor: 'pointer',
                }}
              />
              Receive SMS notifications for order status.
            </label>
          </div>
        ) : null}

        {((storeDataState &&
          storeDataState.is_pickup &&
          storeDataState.is_pickup === 'Yes') ||
          (storeDataState &&
            storeDataState.is_deliver &&
            storeDataState.is_deliver === 'Yes')) &&
        ((visible === 'myPayment' &&
          storeDataState?.offline == 0 &&
          activeHours == true) ||
          (activeHours == false &&
            storeDataState?.future_ordering == 1 &&
            visible === 'myPayment')) ? (
          userData && userData.length !== 0 ? (
            <Button
              className="payment-checkout-with-login"
              onClick={handleCheckout}
              disabled={disableCheckout}
            >
              Checkout
            </Button>
          ) : (
            <>
              <Button
                style={{ marginBottom: '0px' }}
                className="payment-checkout-with-login"
                onClick={handleCheckoutwithLogin}
                disabled={disableCheckout}
              >
                Checkout with Login
              </Button>
              {summaryData?.singleStoreData?.store_option?.is_guest_checkout ===
              '1' ? (
                <Button
                  className="payment-checkout-as-guest"
                  onClick={handleCheckasGuest}
                  disabled={disableCheckout}
                >
                  Checkout As Guest
                </Button>
              ) : (
                ''
              )}
            </>
          )
        ) : (
          ''
        )}

        {isPQLimitReached ? (
          <p className="no-stock-variant-product mt-2 text-center">
            Purchase Quantity Limit Reached!
          </p>
        ) : disableCheckout ? (
          <p className="no-stock-variant-product mt-2 text-center">
            Cannot proceed with Out of Stock products!
          </p>
        ) : null}

        {visible === 'myBillingInfo' &&
        ((storeDataState &&
          storeDataState.is_pickup &&
          storeDataState.is_pickup === 'Yes') ||
          (storeDataState &&
            storeDataState.is_deliver &&
            storeDataState.is_deliver === 'Yes')) ? (
          <Button
            className="payment-checkout-with-login"
            onClick={handleUpdateBillingInfo}
          >
            {isGuest === 'yes' ? 'Proceed' : 'Save & Proceed '}
          </Button>
        ) : (
          ''
        )}

        {visible === 'myDeliveryPayment' &&
        ((storeDataState &&
          storeDataState.is_pickup &&
          storeDataState.is_pickup === 'Yes') ||
          (storeDataState &&
            storeDataState.is_deliver &&
            storeDataState.is_deliver === 'Yes')) ? (
          <DeliveryAddressModal
            selectedDeliveryAdderess={selectedDeliveryAdderess}
            orderMethod={summaryData?.singleStoreData?.orderMethod}
            handlePlaceOrder={handlePlaceOrder}
            orderPlaceApiResponse={orderPlaceApiResponse}
            paymentType={paymentType}
            disablePayButton={disablePayButton}
          />
        ) : (
          ''
        )}
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="delivery-amount-modal-cart"
      >
        <div className="delivery-address-not-selected">
          <div className="not-select-empty-delivery-content">
            <img src={DeliveryAmountIcon} alt="No-products" />
            <h1>
              Sorry, minimum delivery amount is $
              {summaryData?.singleStoreData?.min_delivery_amt}
            </h1>
          </div>
        </div>
        <button
          onClick={handleClose}
          className="delivery-amount-button-cart-ok"
        >
          Ok
        </button>
      </Dialog>
      {/* If Loyalty Point cannot be applied */}
      <Dialog
        open={values.errors.disableLoyaltyPoint}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div"
      >
        <div className="payment-modal">
          <div className="payment-modal-content">
            <img
              src={CannotApplyIcon}
              alt={`cannot-apply-icon`}
              loading="lazy"
            />
            Loyalty Points cannot be applied to your products
          </div>
          <div className="payment-modal-button d-flex justify-content-center mt-4">
            <button
              onClick={() =>
                setValues((prev) => ({
                  ...prev,
                  errors: { ...prev.errors, disableLoyaltyPoint: false },
                }))
              }
            >
              Ok
            </button>
          </div>
        </div>
      </Dialog>
    </>
  ) : (
    ''
  );
};

export default CouponTipSection;
