import SingleStore from './SingleStore';
import SingleStoreLeftMenu from './SingleStoreLeftMenu';
import axios from 'axios';
import SingleStoreTopBar from './SingleStoreTopBar';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleStoresData } from '../../../redux/features/SingleStore/singleStoresSlice';
import { fetchSingleStoreProductData } from '../../../redux/features/SingleStore/singleStoreProductDataSlice';
import { fetchWishlistData } from '../../../redux/features/Wishlist/wishlistSlice';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import { fetchVariantCartData } from '../../../redux/features/Cart/variantCartSlice';
import Validation from '../../../Constants/Validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextSearch } from '../../../Context/SearchContext';
import AgeConfirmationModal from './AgeConfirmationModal';
import Cookies from 'js-cookie';
import { useMediaQuery } from '@mui/material';
import { fetchAllCartProductDetails } from '../../../redux/features/Cart/cartProductDetailsSlice';

const MainSingleStore = ({ visible }) => {
  const { setShowAgeConfirmationModal } = useContext(ContextSearch);
  const SingleStoresDataState = useSelector((state) => state.singleStores);
  let orderRecord = SingleStoresDataState?.storesData?.store_data?.orderMethod;
  let merchant_record =
    SingleStoresDataState?.storesData?.store_data?.merchant_id;
  // console.log(orderRecord)
  let order_store_method = localStorage.setItem('orderRecord', orderRecord);
  let merchat_store_id = localStorage.setItem('merchant_id', merchant_record);
  const { filterVarientDataFromCart } = Validation();
  const location = useLocation();
  const navigate = useNavigate();

  const [merchantId, setMerchantId] = useState(null);

  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const orderMethod = searchParams.get('orderMethod');

  useEffect(() => {
    const invalidOrderMethod =
      orderMethod !== 'delivery' && orderMethod !== 'pickup';
    if (location.pathname.includes('undefined') || invalidOrderMethod) {
      navigate('/');
    }
  }, [location, orderMethod]);

  const cartDataState = useSelector((state) => state.cart.cartData);
  const authUserData = useSelector((state) => state.authUser.UserData);
  const merchantId1 = location.pathname.replace('/wishlist/', '');
  const merchantId2 = location.pathname.replace('/merchant/', '');
  const merchantId3 = location.pathname.replace('/product/', '');
  const merchantId4 = location.pathname.replace('/Loyalty-Point-Zone/', '');
  const merchantId5 = location.pathname.replace('/Store-Credit-Zone/', '');
  const [orderType, setOrderType] = useState(orderMethod);
  const [cart, setCart] = useState(false);
  const isMobileNav = useMediaQuery('(max-width:768px)');
  const [navBar, setNavBar] = useState(!isMobileNav);

  const [singleProductDataPushUp, setSingleProductDataPushUp] = useState();
  const [isOvelay, setIsOvelay] = useState(false);
  const localCartData = useMemo(() => {
    return localStorage.getItem(`cart_${merchantId}`)
      ? JSON.parse(localStorage.getItem(`cart_${merchantId}`))
      : [];
  }, [merchantId]);

  useEffect(() => {
    // console.log('localCartData: ', localCartData);
    if (localCartData?.length > 0) {
      const productIdsString = localCartData
        .map((item) => item?.productId)
        .join(',');

      const data = {
        merchant_id: merchantId,
        product_id: productIdsString,
      };

      dispatch(fetchAllCartProductDetails(data));
      dispatch(setCartData(localCartData));
      // console.log('localCartData: ', localCartData);
      const cartdata = filterVarientDataFromCart(localCartData, merchantId);
      if (Array.isArray(cartdata) && cartdata.length >= 1) {
        cartdata.forEach((variant) => {
          variant && dispatch(fetchVariantCartData(variant));
        });
      }
    }

    const data = {
      merchant_id: merchantId,
      orderMethod: orderType,
      distance: ' ',
      ind_brn: 'no',
    };
    const data_p = {
      merchant_id: merchantId,
      orderMethod: orderType,
      category_id: '',
    };
    if (merchantId && orderType) {
      dispatch(fetchSingleStoresData(data));
      dispatch(fetchSingleStoreProductData(data_p));
    }
  }, [merchantId, orderType, localCartData]);

  useEffect(() => {
    setNavBar(!isMobileNav);
    if (!isMobileNav) {
      setIsOvelay(false);
    }
  }, [isMobileNav]);

  useEffect(() => {
    if (isMobileNav) {
      navBar
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }
  }, [navBar]);

  const handleNavClick = () => {
    if (isMobileNav) {
      setNavBar(!navBar);
    }
  };

  const toggleDrawer = () => {
    // console.log("toggleDrawer")
    setCart(!cart);
  };

  useEffect(() => {
    if (visible === 'wishlist') {
      setMerchantId(merchantId1);
    } else if (visible === 'merchant') {
      setMerchantId(merchantId2);
    } else if (visible === 'product') {
      setMerchantId(merchantId3);
    } else if (visible === 'LoyaltyPointZone') {
      setMerchantId(merchantId4);
    } else if (visible === 'StoreCreditZone') {
      setMerchantId(merchantId5);
    }
  }, [visible, merchantId1, merchantId2, merchantId3]);

  useEffect(() => {
    const datawishlist = {
      id: authUserData && authUserData.id,
      merchant_id: merchantId,
    };
    // console.log(authUserData && authUserData.id , merchantId)
    if (authUserData && authUserData.id && merchantId) {
      dispatch(fetchWishlistData(datawishlist));
    }
  }, [authUserData, merchantId]);

  // useEffect(() => {
  //   if (localCartData && merchantId) {
  //     dispatch(setCartData(localCartData));
  //   }
  // }, [merchantId]);

  useEffect(() => {
    if (cartDataState && cartDataState.length >= 1) {
      const cartdata = filterVarientDataFromCart(localCartData, merchantId);

      if (Array.isArray(cartdata) && cartdata.length >= 1) {
        cartdata.forEach((variant) => {
          variant && dispatch(fetchVariantCartData(variant));
        });
      }
    }
  }, [cartDataState, orderType]);

  // useEffect(() => {
  //   const data = {
  //     merchant_id: merchantId,
  //     orderMethod: orderType,
  //     distance: ' ',
  //     ind_brn: 'no',
  //   };
  //   const data_p = {
  //     merchant_id: merchantId,
  //     orderMethod: orderType,
  //     category_id: '',
  //   };
  //   if (merchantId && orderType) {
  //     dispatch(fetchSingleStoresData(data));
  //     dispatch(fetchSingleStoreProductData(data_p));
  //   }
  // }, [orderType, merchantId]);

  // age verification logic
  useEffect(() => {
    const ageConfirmed = Cookies.get('ageConfirmed');

    if (Boolean(ageConfirmed)) {
      setShowAgeConfirmationModal(() => false);
    } else {
      setShowAgeConfirmationModal(() => true);
    }
  }, []);

  const handleOverlayClickOpen = () => {
    setIsOvelay(true);
  };

  const handleOverlayClickClose = () => {
    setIsOvelay(false);
  };

  return (
    <>
      <AgeConfirmationModal />
      <SingleStoreTopBar
        isOvelay={isOvelay}
        isMobileNav={isMobileNav}
        handleNavClick={handleNavClick}
        navBar={navBar}
        merchantId={merchantId}
        orderType={orderType}
        cartVisible={cart}
        toggleDrawer={toggleDrawer}
        setcartVisible={setCart}
        setSingleProductDataPushUp={setSingleProductDataPushUp}
        handleOverlayClickOpen={handleOverlayClickOpen}
        visible={visible}
      />
      <div className="quickvee-single-store-main-area">
        <SingleStoreLeftMenu
          visible={visible}
          orderType={orderType}
          setOrderType={setOrderType}
          merchantId={merchantId}
          navBar={navBar}
          handleNavClick={handleNavClick}
          handleOverlayClickClose={handleOverlayClickClose}
        />
        <SingleStore
          visible={visible}
          toggleDrawer={toggleDrawer}
          merchantId={merchantId}
          orderType={orderType}
          setOrderType={setOrderType}
          singleProductDataPushUp={singleProductDataPushUp}
          isOvelay={isOvelay}
          handleOverlayClickClose={handleOverlayClickClose}
          handleNavClick={handleNavClick}
        />
      </div>
    </>
  );
};

export default MainSingleStore;
