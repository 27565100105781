import React, { useContext, useEffect, useState } from 'react';
import BongsIMage from '../../Assets/Categories/Bong.png';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ScreenLoader from '../../Assets/screenLoader/Quickvee_loader_.gif';

import {
  BASE_URL_SANDBOX,
  GET_SINGLE_CATEGORY_SEARCH_DATA,
  PRODUCT_IMAGES_BASE_URL,
} from '../../Constants/Config';
import { ContextSearch } from '../../Context/SearchContext';

const HomeCategories = () => {
  const navigate = useNavigate();
  const { fetchCategoryProducts } = useContext(ContextSearch);
  const [fetchingCategory, setFetchingCategory] = useState(null);

  const { noDataFound, setNoDataFound } = useContext(ContextSearch);
  const allStoresStateData = useSelector(
    (state) => state.homeStores.homeStoresData.defaults,
  );
  const [allCategories, setAllCategories] = useState();

  useEffect(() => {
    if (Array.isArray(allStoresStateData)) {
      setAllCategories(allStoresStateData.slice(0, 7));
    }
  }, [allStoresStateData]);

  const checkCategoryProducts = async (categoryName) => {
    try {
      setFetchingCategory(() => categoryName);

      const responseData = await fetchCategoryProducts(categoryName);

      if (responseData?.store_products?.length > 0) {
        navigate(
          `/category/${responseData?.smartsearch}?category=${responseData?.smartsearch}`,
        );
        setNoDataFound(false);
      } else {
        setNoDataFound(true);
      }
    } catch (e) {
      console.log('Error: ', e);
    } finally {
      setFetchingCategory(() => null);
    }
  };

  useEffect(() => {
    if (noDataFound) {
      setTimeout(() => setNoDataFound(false), 3000);
    }
  }, [noDataFound]);

  return (
    <>
      {/* Error Section */}
      {noDataFound && (
        <div className="alert home_alert_div" role="alert">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setNoDataFound(false)}
          >
            <img
              style={{ backgroundColor: '#f5f9ff' }}
              aria-hidden="true"
              src={BASE_URL_SANDBOX + 'quickimages/home/alert_close.svg'}
              alt=""
            />
          </button>
          <h3>Sorry! We couldn't find any matches</h3>
          <p>Please check the spelling or try searching something else.</p>
        </div>
      )}
      <Swiper
        spaceBetween={10}
        breakpoints={{
          1024: {
            slidesPerView: 7,
            spaceBetween: 10,
          },
          991: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          420: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          360: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {allCategories &&
          allCategories.map((item, index) => (
            <SwiperSlide key={index} style={{ position: 'relative' }}>
              {fetchingCategory === item?.name ? (
                <>
                  <div className="home_page_top_category_div cursor-pointer">
                    <div className="q-single-category-link-home">
                      <div className="home_page_top_category_div_inner">
                        <img
                          className="category-image"
                          src={ScreenLoader}
                          alt={item.name}
                        />
                      </div>
                      <span>Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="home_page_top_category_div cursor-pointer">
                  <div
                    onClick={() => {
                      if (!fetchingCategory) {
                        checkCategoryProducts(item?.name);
                      }
                    }}
                    style={{
                      cursor: fetchingCategory ? 'not-allowed' : 'pointer',
                    }}
                    className="q-single-category-link-home"
                  >
                    <div className="home_page_top_category_div_inner">
                      <img
                        className="category-image"
                        // src={`${BASE_URL_SANDBOX}upload/defaults_images/${item.media}`}
                        src={`${PRODUCT_IMAGES_BASE_URL}upload/defaults_images/${item.media}`}
                        alt={item.name}
                      />
                    </div>
                    <span>{item.name}</span>
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default HomeCategories;
