import React, { useState, useEffect, useRef } from 'react';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';
import InfoOutlinedIcon from '../../../Assets/Store/CouponInfo.svg';
import LocationIcon from '../../../Assets/Store/location.svg';
import { Link } from 'react-router-dom';
import { Button, Drawer, Skeleton } from '@mui/material';
import ViewFullHours from './ViewFullHours';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import cancleBtn from './../../../Assets/Store/cross.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import { useDispatch } from 'react-redux';
import {
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_BASE_URL,
} from '../../../Constants/Config';

const SingleStoreLeftMenu = ({
  visible,
  setOrderType,
  orderType,
  merchantId,
  navBar,
  handleNavClick,
  handleOverlayClickClose,
}) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [singleStoreData, setSingleStoreData] = useState(null);
  const [categories, setCategories] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categorysId = searchParams.get('categoryid');
  let order_method = localStorage.getItem('orderRecord');
  const handleOrderStatus = (ordertype) => {
    setOrderType(ordertype);
    const params = new URLSearchParams(location.search);
    params.set('orderMethod', ordertype);

    // http://localhost:3000/merchant/MAL0100CA?orderMethod=pickup//

    Navigate(`/merchant/${merchantId}?orderMethod=${ordertype}`);
    // Navigate({
    //   pathname: location.pathname,
    //   search: params.toString(),
    // });
    localStorage.removeItem(`cart_${merchantId}`);
    dispatch(setCartData([]));
  };

  const SingleStoresDataState = useSelector((state) => state.singleStores);
  const { isGuest } = useSelector((state) => state.authUser);

  useEffect(() => {
    if (
      !SingleStoresDataState.loading &&
      SingleStoresDataState?.storesData?.store_data &&
      SingleStoresDataState.storesData.categories
    ) {
      setSingleStoreData(SingleStoresDataState.storesData.store_data);
      const categories = SingleStoresDataState.storesData.categories.filter(
        (category) =>
          category?.cat_show_status === '0' ||
          category?.cat_show_status === '1',
      );

      // const removedCategories =
      //   SingleStoresDataState.storesData.categories.filter(
      //     (category) =>
      //       category?.cat_show_status !== '0' &&
      //       category?.cat_show_status !== '1',
      //   );

      // console.log('categories: ', categories);
      // console.log('removedCategories from left sidebar: ', removedCategories);
      setCategories(categories);
    }
  }, [SingleStoresDataState, SingleStoresDataState.loading]);

  const [scrolled, setScrolled] = useState('');

  const getScrollPosition = () => {
    // Assuming there is only one element with the given class name
    const element = document.querySelector('.quickvee-single-store-left-menu');

    if (element) {
      const scroll = element.scrollTop;

      if (scroll > 20) {
        setScrolled(scroll); // Assuming setScrolled is a function you have defined elsewhere
      } else {
        setScrolled('');
      }
    }
  };

  const sidebarRef = useRef(null);

  useEffect(() => {
    // Set initial width of the sidebar based on the navBar prop
    sidebarRef.current.style.left = navBar ? '0px' : '-250px';
    // sideBarContent.current.style.opacity = navBar ? '1' : '0';
  }, [navBar]);

  const skeleton = Array.from({ length: 15 }, (_, index) => (
    <Skeleton
      variant="rounded"
      width={210}
      height={30}
      key={index}
      style={{ margin: '5px 0' }}
    />
  ));

  return (
    <>
      {!SingleStoresDataState.loading && SingleStoresDataState.error ? (
        <h1> Error : {SingleStoresDataState.error} </h1>
      ) : null}
      <div className="quickvee-single-store-left-menu" ref={sidebarRef}>
        <div
          className={`quickvee-single-store-logo ${
            scrolled > 20 ? 'scrolled' : ''
          }`}
        >
          <div
            onClick={() => {
              handleNavClick();
              handleOverlayClickClose();
            }}
            className="cancleBtn-div"
          >
            <img src={cancleBtn} alt="cancelbtn" />
          </div>
          {SingleStoresDataState.loading ? (
            <Skeleton
              variant="circular"
              width={100}
              height={100}
              style={{ borderRadius: '50%' }}
            />
          ) : (
            <Link
              to={`/merchant/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
            >
              <img
                src={
                  singleStoreData?.ses_m_img
                    ? // ? `${BASE_URL_SANDBOX}upload/${singleStoreData?.ses_m_img}`
                      `${PRODUCT_IMAGES_BASE_URL}upload/${singleStoreData?.ses_m_img}`
                    : ProductDefaultImage
                }
                alt="store"
                loading="lazy"
                onError={(e) => {
                  e.target.src = ProductDefaultImage;
                }}
              />
            </Link>
          )}
          {SingleStoresDataState.loading ? (
            ''
          ) : singleStoreData?.store_oc_status === 'open' ? (
            <span
              style={
                scrolled > 0
                  ? { display: 'none', transition: ' all 0.5s' }
                  : { background: '#14AE2D' }
              }
            >
              Open
            </span>
          ) : (
            <span
              style={
                scrolled > 0
                  ? {
                      display: 'none',
                      transition: ' all 0.5s',
                    }
                  : { background: '#E55252' }
              }
            >
              Closed
            </span>
          )}

          <div
            className={`quickvee-left-menu-single-store-detail-sticky ${
              scrolled > 0 ? 'scrolled' : ''
            }`}
          >
            {singleStoreData ? (
              <div
                className={`quickvee-single-store-content ${
                  scrolled > 0 ? 'scrolled' : ''
                }`}
              >
                <span>
                  {singleStoreData?.store_name
                    ? singleStoreData.store_name
                    : ''}
                  {/* <img
                      src={InfoOutlinedIcon}
                      alt="quickvee-store-info"
                      style={{ marginLeft: '5px' }}
                      loading="lazy"
                    /> */}
                </span>
                <p className={` ${scrolled > 0 ? 'scrolled' : ''}`}>
                  <img src={LocationIcon} alt="location" loading="lazy" />
                  {singleStoreData && singleStoreData.add
                    ? singleStoreData.add + ', '
                    : ''}

                  {scrolled > 0 ? (
                    '...'
                  ) : (
                    <>
                      {singleStoreData?.city ? singleStoreData.city + ', ' : ''}
                      {singleStoreData?.state
                        ? singleStoreData.state + ', '
                        : ''}
                      {singleStoreData?.zip ? singleStoreData.zip + '. ' : ''}
                    </>
                  )}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="quickvee-left-menu-single-store-detail">
          {SingleStoresDataState.loading ? (
            <Skeleton variant="rounded" width={210} height={60} />
          ) : (
            <>
              {/* <div className="quickvee-single-store-content">
                <span>
                  {singleStoreData && singleStoreData.store_name
                    ? singleStoreData.store_name + ', '
                    : ''}
                 
                </span>
                <p>
                  {' '}
                  <img src={LocationIcon} alt="location" loading="lazy" />
                  {singleStoreData && singleStoreData.add
                    ? singleStoreData.add + ', '
                    : ''}
                  {singleStoreData && singleStoreData.city
                    ? singleStoreData.city + ', '
                    : ''}
                  {singleStoreData && singleStoreData.state
                    ? singleStoreData.state + ', '
                    : ''}
                  {singleStoreData && singleStoreData.zip
                    ? singleStoreData.zip + '. '
                    : ''}
                </p>
              </div> */}
              <div>
                {scrolled > 0 ? (
                  ''
                ) : (
                  <ViewFullHours
                    SingleStoresDataState={SingleStoresDataState}
                    orderMethod={orderType}
                  />
                )}
              </div>

              {scrolled > 0 ? (
                ''
              ) : (
                <div
                  className={
                    singleStoreData?.is_deliver === 'Yes' ||
                    singleStoreData?.is_pickup === 'Yes'
                      ? 'quickvee-single-store-pickup-delivery'
                      : ''
                  }
                >
                  {singleStoreData?.is_deliver === 'Yes' ? (
                    <Button
                      disabled={
                        orderType !== 'pickup' ||
                        singleStoreData?.is_pickup == 'No'
                          ? true
                          : false
                      }
                      className={
                        orderType === 'delivery' ||
                        singleStoreData?.is_pickup == 'No'
                          ? 'active-delivery-button'
                          : ''
                      }
                      onClick={() => {
                        handleOrderStatus('delivery');
                        handleNavClick();
                        handleOverlayClickClose();
                      }}
                    >
                      Delivery
                    </Button>
                  ) : (
                    ''
                  )}
                  {singleStoreData?.is_pickup === 'Yes' ? (
                    <Button
                      disabled={
                        orderType !== 'delivery' ||
                        singleStoreData?.is_deliver == 'No'
                          ? true
                          : false
                      }
                      className={
                        orderType === 'pickup' ||
                        singleStoreData?.is_deliver == 'No'
                          ? 'active-delivery-button'
                          : ''
                      }
                      onClick={() => {
                        handleOrderStatus('pickup');
                        handleNavClick();
                        handleOverlayClickClose();
                      }}
                    >
                      Pickup
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </>
          )}
          {SingleStoresDataState.loading ? (
            <>
              <Skeleton
                variant="rounded"
                width={210}
                height={30}
                style={{ margin: '20px 0 5px 0' }}
              />
              <Skeleton
                variant="rounded"
                width={210}
                height={30}
                style={{ margin: '5px 0' }}
              />
              <Skeleton
                variant="rounded"
                width={210}
                height={30}
                style={{ margin: '5px 0' }}
              />
            </>
          ) : (
            <div className="quickvee-single-store-reorder-wishlist">
              {/* ADD CONDITION BY PRIYA  FOR GUEST CHECKOUT (REMOVE WISHLIST */}

              {scrolled > 0 ? (
                ''
              ) : (
                <>
                  {isGuest !== 'yes' ? (
                    <Link
                      onClick={() => {
                        handleNavClick();
                        handleOverlayClickClose();
                      }}
                      to={`/wishlist/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
                      className={
                        visible === 'wishlist'
                          ? 'quickvee-single-store-link-active quickvee-single-store-link'
                          : 'quickvee-single-store-link'
                      }
                    >
                      <p className="wishlist-icon"></p>
                      <span>Wishlist</span>
                    </Link>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>

        {SingleStoresDataState.loading ? (
          <div className="loading-category-menu-listing-elements">
            {skeleton}
          </div>
        ) : (
          <div className="quickvee-left-menu-single-store-navigations">
            <Link
              onClick={() => {
                handleNavClick();
                handleOverlayClickClose();
              }}
              to={`/merchant/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
              className={
                visible === 'merchant' && categorysId == null
                  ? 'single-store-all-products-active single-store-all-products'
                  : 'single-store-all-products'
              }
            >
              {' '}
              All Products{' '}
            </Link>

            {categories &&
              categories.map((category, index) => (
                <Link
                  onClick={() => {
                    handleNavClick();
                    handleOverlayClickClose();
                  }}
                  key={category.id}
                  to={`/merchant/${category.merchant_id}?category=${category.title}&orderMethod=${orderType}&categoryid=${category.id}`}
                  className={`${
                    categorysId === category.id
                      ? 'single-store-all-products-active'
                      : ''
                  }`}
                >
                  {category.title}
                </Link>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SingleStoreLeftMenu;
